import React, { useEffect } from 'react';
import LayoutStepStart from "../../../hoc/LayoutStepStart/LayoutStepStart";
import { resetSecondStep } from "../../../store/actions/investorProfileActions";
import { connect } from "react-redux";
import { retirementCalcLinks } from "../../../Routes/RoutesConst";

const Step2 = ( props ) => {

    useEffect(()=>{
        props.resetSecondStep()
    })
    return (

        <LayoutStepStart
            title ='YOUR INVESTOR PROFILE'
            step={2}
            next={retirementCalcLinks.shortTermLosses}
        >
            <p>Successful investing starts with an understanding of your investment goals, risk tolerance and time horizon.</p>
            <p>Let’s find out yours.</p>
        </LayoutStepStart>
    )
};

function mapDispatchToProps(dispatch) {
    return {
        resetSecondStep: ()=>dispatch(resetSecondStep())
    }
}

export default connect(null, mapDispatchToProps)(Step2);